import { create } from "zustand";
import type {
	Banner,
	Doctor,
	DoctorSchedule,
	Faskes,
	Patient,
	Payment,
} from "~/types";
import { apiFetcher } from "./utils.client";

type AppState = {
	faskes: Faskes | null;
	patient: Patient | null;
	banners: Banner[] | null;
	csrf: string | null;
	setFaskes: (faskes: Faskes) => void;
	fetchBanners: () => void;
	setCsrf: (csrf: string) => void;
	setPatient: (patient: Patient) => void;
};
export const useAppState = create<AppState>((set) => ({
	faskes: null,
	patient: null,
	banners: null,
	csrf: null,
	setFaskes: (faskes: Faskes) => set({ faskes }),
	fetchBanners: async () => {
		const banners = await apiFetcher<Banner[]>({
			path: "/v1/booking-online/booking/getbanner",
			opts: {},
		});
		set({ banners });
	},
	setCsrf: (csrf) => set({ csrf }),
	setPatient: (patient: Patient) => set({ patient }),
}));

type BookingState = {
	doctor: Doctor | null;
	setDoctor: (doctor: Doctor | null) => void;
	schedule: DoctorSchedule | null;
	setSchedule: (schedule: DoctorSchedule | null) => void;
	payment: Payment | null;
	setPayment: (payment: Payment | null) => void;
};

export const useBookingState = create<BookingState>((set) => ({
	doctor: null,
	schedule: null,
	payment: null,
	setPayment: (payment) => set({ payment }),
	setDoctor: (doctor) => set({ doctor }),
	setSchedule: (schedule) => set({ schedule }),
}));
